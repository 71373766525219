<template>
  <div class="competition">
    <div class="title">
      <div class="titlenew fontbold">Competition</div>
    </div>
    <div class="content">
      <div class="theimage">
        <img
          src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/底图.png"
          alt=""
        />
      </div>
      <div class="game">
        <div>
          Global Digital Skills Championship (GDSC) is founded by the
          International Alliance for Digital Game Education (IADGE) to focus on
          the future advanced information technology and the cultivation of
          high-end compound skilled talents needed by computer graphics
          industry. The competition include 3D Digital Game Art, AR/VR and 3D
          Animation Technology.
        </div>
        <div>Purpose</div>
        <div>
          In order to deeply promote the industry's vertical deepening, digital
          and international development based on advanced computer graphics
          technology, IADGE founded the Global Digital Skills Championship
          (GDSC). At present, the competition includes three programs: 3D
          Digital Game Art, AR/VR and 3D Animation Technology.
        </div>
        <div>
          The competition is guided by the standard of WorldSkills competition,
          with the goal of cultivating and selecting high skilled talents,
          promoting the two-way positive interaction between competition and
          talent cultivation, creating professional competition brand,
          innovating competition form, broadening competition coverage and
          improving technical level.
        </div>
        <div>
          Based on the whole country and facing the world, the competition will
          be conducted in strict accordance with the international competition
          standards and the principles of openness, fairness and impartiality to
          ensure the transparency and authority of the competition.
        </div>
      </div>
    </div>
    <div class="forimage">
      <div class="leftimage">
        <div style="margin-bottom:0px">
          <img
            src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/矩形 8 拷贝 2.png"
            alt=""
          />
        </div>
        <div style="margin-right: 30px">
          <img
            src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/矩形 16.png"
            alt=""
          />
        </div>
      </div>
      <div class="rightimage">
        <div class="topone">
          <img
            src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/矩形 15.png"
            alt=""
          />
        </div>
        <div class="bottonimage">
          <div>
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/矩形 15 拷贝.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220614/矩形 16.----jpg.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
@import "../sass/index";
.competition {
  max-width: 1200px;
  margin: 132px auto 215px;
  .title {
    margin-bottom: 50px;
    .titlenew {
      @include fontstyle(#1b78c2, 80px, bold, auto);
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
    .theimage {
      //   width: 100%;
      min-height: 1000px;
      img {
        width: 100%;
      }
    }
    .game {
      position: absolute;
      padding: 50px;
      max-width: 1100px;
      text-align: left;
      background-color: #fff;
      top: 330px;
      box-sizing: border-box;
      :nth-child(1) {
        @include fontstyle(#000, 18px, 400, auto);
      }
      :nth-child(2) {
        @include fontstyle(#000, 30px, bold, auto);
        margin: 40px 0px;
      }
      :nth-child(3) {
        @include fontstyle(#000, 18px, 400, auto);
        margin-bottom: 40px;
      }
      :nth-child(4) {
        @include fontstyle(#000, 18px, 400, auto);
        margin-bottom: 40px;
      }
      :nth-child(5) {
        @include fontstyle(#000, 18px, 400, auto);
      }
    }
  }
  .content {
    display: flex;
  }
  .forimage {
    display: flex;
    .leftimage {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      :nth-child(1) {
        margin: 0 30px 30px 0px;
      }
    }
    .rightimage {
      display: flex;
      flex-direction: column;
      .bottonimage {
        display: flex;
      }
      .topone {
        margin-bottom: 30px;
      }
      .bottonimage {
        :nth-child(2) {
          margin-left: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .competition {
    max-width: 760px;
  }
}
@media screen and (max-width: 500px) {
  .theimage {
    //   width: 100%;
    min-height: 1400px !important;
  }
}
@media screen and (max-width: 768px) {
  .competition {
    padding: 30px 20px;
    margin: 0 auto 215px;
    .title {
      .titlenew {
        @include fontstyle(#1b78c2, 32px, bold, auto);
      }
    }
    // .title {
    //   margin-bottom: 50px;
    //   .titlenew {
    //     @include fontstyle(#1b78c2, 60px, bold, auto);
    //   }
    // }
    .content {
      .theimage {
        min-height: 1200px;
      }
      .game {
        top: 180px;
        padding: 0px;
      }
    }
    .forimage {
      flex-direction: column;
      .leftimage {
        flex-direction: row;
      }
    }
  }
}
</style>
