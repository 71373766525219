<template>
  <div class="Venue">
    <div class="title">
      <div class="titlenew fontbold">Venue</div>
    </div>
    <div class="content">
      <div class="title">
        <div v-for="item in typelist" :key="item.id" class="fortype">
          <a href=""> {{ item.gdsc_venue_group_type_name }}</a>
        </div>
      </div>
      <div class="typecontent">
        <div
          class="allcontent"
          v-for="(item, index) in contentlist"
          :key="index"
        >
          <!-- <div v-for="(sun, index) in item" :key="index"> -->
          <div class="totitle">
            {{ item.gdsc_venue_group_type_name }}
            <!-- {{ item[1][index] }} -->
            <!-- {{ item.logo.substr(item.logo.length - 2, 2) }} -->
          </div>
          <div>
            <img
              :src="
                'http://iadge.oss-cn-hangzhou.aliyuncs.com/' + item.logo
              "
              :style="
                item.logo.substr(item.logo.length - 2, 2) == 'vg' &&
                'width: 200px'
              "
              alt=""
            />
          </div>
          <div>
            {{ item.name }}
          </div>
          <div v-html="item.describe"></div>
        </div>

        <div
          class="titles"
          style="text-align: left"
          v-for="(item, index) in originzer.slice(0, 1)"
          :key="index"
        >
          {{ item.gdsc_venue_group_type_name }}
        </div>
        <div class="allimage">
          <div class="alllogo" v-for="(item, index) in originzer" :key="index">
            <img
              :src="
                'http://iadge.oss-cn-hangzhou.aliyuncs.com/' + item.logo
              "
              alt=""
            />
          </div>
        </div>

        <div
          class="titles"
          style="text-align: left; margin-top: 100px"
          v-for="(item, index) in partner.slice(0, 1)"
          :key="index"
        >
          {{ item.gdsc_venue_group_type_name }}
        </div>
        <div class="allimage">
          <div class="alllogo" v-for="(item, index) in partner" :key="index">
            <img
              :src="
                'http://iadge.oss-cn-hangzhou.aliyuncs.com/' + item.logo
              "
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { venuegroup_type, venuedetails } from "@/api/api";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      contentlist: [],
      typelist: [],
      originzer: [],
      partner: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    venuegroup_type({
      year: "2022",
    }).then((res) => {
      this.typelist = res.data.groupTypeName;
      // console.log(this.typelist,121313131414);
    });
    venuedetails({
      year: "2022",
    }).then((res) => {
      for (const key in res.data) {
        if (Object.hasOwnProperty.call(res.data, key)) {
          // console.log(res.data[key],123);
          this.contentlist.push(...res.data[key]);
        }
      }
    });
  },
  methods: {},
};
</script>

<style lang="scss">
@import "../sass/index";
.Venue {
  max-width: 1280px;
  margin: 132px auto 215px;
  .title {
    .titlenew {
      @include fontstyle(#1b78c2, 80px, bold, auto);
    }
  }
  .content {
    margin-top: 50px;
    display: flex;
    .title {
      height: 140px;
      display: flex;
      flex-direction: column;
      padding: 30px 130px 35px 30px;
      background-color: #f7f7f7;
      @include fontstyle(#bf0075, 18px, bold, auto);

      margin-right: 50px;
      .fortype {
        // display: flex;
        position: relative;
        text-align: left;
        &:nth-child(n + 1) {
          margin-top: 10px;
        }
        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 6px;
          height: 3px;
          background-color: #bf0075;
          // margin: 8px 40px 0 0px;
          top: 10px;
          left: 10px;
        }
        a {
          margin-left: 30px;
        }
      }
    }
    .typecontent {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-self: left;
      // &:nth-child(n+1){
      //   margin-top: 30px;
      // }
      .allcontent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // &:nth-child(n + 1) {
        //   margin-top: 100px;
        // }

        .totitle {
          @include fontstyle(#bf0075, 30px, bold, auto);
          margin-bottom: 30px;
        }
        :nth-child(3) {
          text-align: left;
          margin-top: 44px;
        }
        :nth-child(4) {
          text-align: left;
          margin-bottom: 100px;
        }
      }
      .titles {
        @include fontstyle(#bf0075, 30px, bold, auto);
      }
      .allimage {
        display: flex;
        flex-wrap: wrap;

        .alllogo {
          max-width: 33.3%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 768px) {
  .Venue {
    padding: 30px 20px;
    margin: 0 auto 215px;
    .title {
      .titlenew {
        @include fontstyle(#1b78c2, 32px, bold, auto);
      }
    }
  }
  .content {
    flex-direction: column;
    .title {
      margin-right: 0px !important;
      margin-bottom: 20px;
      padding: 0px !important;
    }
  }
}
</style>
