<template>
  <div class="newsdetail">
    <div class="fontstyleone">
      Media > News > <span style="color: #bf0075">Competition</span>
    </div>
    <div class="sild"></div>
    <div class="titleordate">
      <div class="titleupdate">
        <div>
          {{ typelist.title }}
        </div>
        <div>
          <img
            src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220321/删除.svg"
            alt=""
            class="hands"
            @click="
              topath == true
                ? this.$router.push('/news')
                : this.$router.back(-1)
            "
          />
        </div>
      </div>
      <div class="date">
        <img
          src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220321/时间.svg"
          alt=""
        />
        <div>
          {{ typelist.creat_time }}
        </div>
      </div>
    </div>
    <div class="htdes" v-html="typelist.describe"></div>
    <div class="more">More</div>
    <div class="newsall">
      <div
        class="forcotent"
        v-for="(item, index) in morelist"
        :key="index"
        @click="tonewspath(item.id)"
      >
        <div class="whileimage">
          <img
            :src="
              'http://iadge.oss-cn-hangzhou.aliyuncs.com/' + item.image
            "
            alt=""
          />
        </div>
        <div class="whiletitle">
          {{ item.title }}
        </div>
        <div class="whiletitle time">
          {{ item.creat_time }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newsdetail, morenews } from "@/api/api";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      typelist: [],
      morelist: [],
      topath: false,
    };
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      handler() {
        var newid = this.$route.params.id;
        newsdetail({ id: newid }).then((res) => {
          this.typelist = res.data;
          // console.log(res.data, 12);
        });
        morenews({
          year: 2022,
          groupTypeName: "",
          showNumber: 2,
          newId: this.$route.params.id,
        }).then((res) => {
          this.morelist = res.data;
          console.log(res.data, 123);
        });
        // console.log(newid, 12345);
      },
    },
  },
  created() {},
  mounted() {
    const artworkId = Number(this.$route.path.replace("/newsdetail/", ""));
    // newsdetail({ id: artworkId }).then((res) => {
    //   this.typelist = res.data;
    //   // console.log(res.data, 12);
    // });
  },
  methods: {
    tonewspath(el) {
      this.topath = true;
      this.$router.push({ name: "newsdetail", params: { id: el } });
      // console.log(el,123);
    },
  },
};
</script>

<style lang="scss">
@import "../sass/index";
.fontstyleone {
  @include fontstyle(#000, 18px, 400, auto);
  text-align: left;
}
.newsdetail {
  margin: 92px auto 81px;
  max-width: 1280px;
  .htdes {
  }
  .newsall {
    display: flex;
    justify-content: space-between;
    .forcotent {
      max-width: 48%;
      overflow: hidden;
      cursor: pointer;
      .time {
        margin-bottom: 20px;
      }
      .whileimage {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 640px;
        height: 300px;
      }
    }
  }

  .sild {
    margin: 18px 0 54px 0;
    height: 1px;
    background: #f1f1f1;
  }
  .titleordate {
    display: flex;
    flex-direction: column;
    .titleupdate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .hands {
        cursor: pointer !important;
      }
      :nth-child(1) {
        text-align: left;
        @include fontstyle(#000, 46px, bold, auto);
      }
      :nth-child(2) {
        font-weight: 800;
        width: 25px;
        cursor: pointer;
      }
    }
    .date {
      display: flex;
      align-items: center;
      :nth-child(1) {
        width: 19px;
        margin-right: 12px;
      }
      :nth-child(2) {
        text-align: left;
        @include fontstyle(#1b78c2, 16px, 400, auto);
        margin: 23px 0px 18px;
      }
    }
  }
  .more {
    @include fontstyle(#bf0075, 48px, bold, auto);
    margin-bottom: 50px;
    margin-top: 100px;
  }
  .whiletitle {
    margin-top: 15px;
  }
}
@media screen and (max-width: 1280px) {
  .newsdetail {
    margin: 66px auto 100px;
    max-width: 760px;
    .htdes {
      p {
        display: inline-block;
      }
      img {
        height: auto !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .newsdetail {
    padding: 30px 20px;
  }
  .titleupdate {
    :nth-child(2) {
      font-weight: 800;
      width: 50px !important;
      cursor: pointer;
    }
  }
  .newsall {
    flex-direction: column;
    .forcotent {
      max-width: 100% !important;
      // width: 100%;
    }
  }
  .whileimage {
    width: auto !important;
    height: 220px !important;
  }
}
</style>
