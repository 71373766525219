<template>
  <div class="photosdetail">
    <div class="imageturnpage">
      <div class="theimages">
        <!-- <el-carousel :interval="1000" type="card" height="450px">
          <el-carousel-item> -->
        <div class="leftimage">
          <img
            src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220323/向左1.svg"
            alt=""
            :class="(left && 'active') || 'hand'"
            @click="popver('left')"
          />
        </div>
        <div class="forimage">
          <!-- <img
            :src="
              'http://meshmellow.oss-cn-hangzhou.aliyuncs.com/' +
              photosdetail.image
            "
            v-if="!photosdetail.video"
            alt=""
          /> -->
          <!-- controls=”controls”

x5-playsinline=””

playsinline=”true”

webkit-playsinline=”true”

x-webkit-airplay=”true”

x5-video-player-type=”h5″

x5-video-player-fullscreen=””

x5-video-orientation=”portraint”


x5-playsinline
            playsinline
            webkit-playsinline
            x-webkit-airplay
            x5-video-player-type
            x5-video-player-fullscreen
            x5-video-orientation
            controls="”controls”"
 -->
          <video
            v-if="!this.$store.state.show"
            autoplay="autoplay"
            style="max-width: 1000px width:100%"
            :src="photosdetail.video"
          ></video>
        </div>
        <!-- </el-carousel-item>
        </el-carousel> -->
        <div class="rightimage">
          <img
            src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220323/向左1.svg"
            alt=""
            :class="(right && 'active') || 'hand'"
            @click="popver('right')"
          />
        </div>
      </div>
    </div>
    <div class="titledetail">
      <div class="delete">
        <img
          src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220321/删除.svg"
          alt=""
          class="hand"
          @click="
            topath == true
              ? this.$router.push('/photos')
              : this.$router.back(-1)
          "
        />
      </div>
      <div class="date">
        <div>
          <img
            src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220321/时间.svg"
            alt=""
          />
        </div>
        <div>
          {{ photosdetail.creat_time }}
        </div>
      </div>
      <div class="introduction" v-html="photosdetail.describe"></div>
      <div class="imagepage">
        {{ photosdetail.sequence_number }}/{{ photosdetail.total }}
      </div>
    </div>
  </div>
</template>

<script>
import { Photosdetail } from "@/api/api";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      photosdetail: [],
      pid: "",
      // 控制左右按钮的样式
      left: false,
      right: false,
      // 控制返回路径
      topath: false,
      l: "left",
    };
  },
  computed: {},
  watch: {
    // immediate: true,
    $route: {
      handler() {
        const artworkId = Number(
          this.$route.path.replace("/videosdetail/", "")
        );
        // console.log(artworkId, 124);
        this.pid = artworkId;
        Photosdetail({
          id: artworkId,
          classify: "videos",
          year: "2022",
        }).then((res) => {
          this.photosdetail = res.data;
          console.log(this.photosdetail, 123);
          // if (!this.photosdetail.id) {
          //   // console.log(1245);
          //   if (this.l == "left") {
          //     this.pid--;
          //   } else {
          //     this.pid++;
          //   }
          //   this.$router.push({
          //     name: "videosdetail",
          //     params: { id: this.pid },
          //   });
          // }
        });
      },
      immediate: true,
      // async handler() {
      //   const artworkId = Number(this.$route.path.replace("/phtosdetail/", ""));
      //   this.photosdetail = await Photosdetail(artworkId);
      //   console.log(this.photosdetail, 124);
      // },
    },
  },
  created() {},
  mounted() {},
  methods: {
    popver(e) {
      this.topath = true;
      this.l = e;
      if (e == "left") {
        this.right = false;
        this.$router.push({
          name: "videosdetail",
          params: { id: this.photosdetail.next_id },
        });
        // this.$route.params.id = ;
        // console.log(this.$route.params.id, 999);
        // console.log(e);
      } else if (e == "right") {
        this.left = false;
        // this.$route.params.id = this.pid + 1;
        this.$router.push({
          name: "videosdetail",
          params: { id: this.photosdetail.prev_id },
        });
        // console.log(e);
      } else {
        if (this.pid == 1) {
          this.left = true;
        } else {
          this.right = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../sass/index";

.active {
  cursor: no-drop;
}

.photosdetail {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;

  .imageturnpage {
    background-color: #000;
    padding: 124px 102px;
    // width: 60%;
    position: relative;

    .theimages {
      display: flex;
      align-items: center;
      justify-content: center;

      .leftimage {
        position: absolute;
        left: 30px;
        width: 30px;
      }

      .forimage {
        video {
          width: 100%;
          max-width: 1000px;
          z-index: 3;
        }
      }

      .rightimage {
        transform: rotate(180deg);
        position: absolute;
        right: 30px;
        width: 30px;
      }
    }

    img {
      display: block;
    }
  }

  .titledetail {
    flex: 1;
    padding: 214px 106px 0px 75px;
    display: flex;
    flex-direction: column;
    position: relative;

    img {
      width: 21px;
    }

    .delete {
      position: absolute;
      align-self: end;
      top: 40px;
      right: 40px;
    }

    .date {
      display: flex;
      align-items: center;
      color: #1b78c2;

      //   margin-top: 150px;
      :nth-child(1) {
        margin-right: 2px;
      }
    }

    .introduction {
      text-align: left;
      font-size: 20px;
    }

    .imagepage {
      color: #b2b2b0;
      text-align: left;
      margin: 160px 0 0;
      //   display: flex;
    }
  }
}

@media screen and (max-width: 1280px) {
  .photosdetail {
    flex-direction: column;

    .titledetail {
      padding: 60px;
    }
  }

  .imagepage {
    margin: 0 0 80px 0 !important;
  }
}
</style>
