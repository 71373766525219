<template>
  <div>
    <div class="detail">
      <div class="title">
        <div class="titlenew">{{ skillslist.title }}</div>
      </div>
      <div class="titleskills" v-html="skillslist.describe"></div>
    </div>

    <div class="viewcenter">
      <div class="viewinter">
        <el-carousel
          :interval="1000"
          autoplay="flase"
          type="card"
          pause-on-hover
          initial-index="1"
          height="450px"
        >
          <el-carousel-item v-for="(item, index) in imagelist" :key="index">
            <div class="forimage">
              <img
                :src="'http://iadge.oss-cn-hangzhou.aliyuncs.com/' + item"
                alt=""
              />
            </div>
          </el-carousel-item>
          <el-carousel-item v-for="(item, index) in videolist" :key="index">
            <div class="forvideo">
              <!-- 
                 x5-playsinline
                playsinline
                webkit-playsinline
                x-webkit-airplay
                x5-video-player-type
                x5-video-player-fullscreen
                x5-video-orientation
               -->
              <!--  controls="”controls”" -->
              <video v-if="!this.$store.state.show">
                <source :src="item.video" type="video/mp4" />
              </video>
              <img
                class="start"
                style="width: 80px !important"
                src="
              http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220221/播放按钮.png"
                alt=""
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="result">
      <div class="resulttitle fontbold">Result of GDSC 2022</div>
      <div class="resulttype">
        <!-- @click="totype()" -->
        <div :class="typelab && 'active'" class="hand" @click="totype()">
          Online Selection
        </div>
        <div :class="!typelab && 'active'" class="hand" @click="totype()">
          Offline Finals
        </div>
      </div>
      <div class="staff" v-show="typelab">
        <div class="poin">
          <div class="game" v-for="(item, index) in gamelist" :key="index">
            <div class="toimage">
              <img :src="item.pivot.cover" alt="" />
            </div>
            <!-- <div v-for="(child, index) in item.pivot" :key="index"> -->
            <div class="uname">
              {{ item.pivot.en_username }}
            </div>
            <div class="ushool">
              {{ item.pivot.en_school }}
            </div>
            <!-- </div> -->

            <!-- {{ item.id }} -->
          </div>
        </div>

        <!-- <div class="one">
          <div class="">
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220329/001.jpg"
              alt=""
            />
            <div class="prize">
              <span class="ranking fontbold"> 1st Prize </span>
              Huang Roufen
            </div>
            <div class="address">Shenzhen Institute of Technology</div>
          </div>
        </div> -->
        <!-- <div class="two">
          <div class="lastchild">
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220329/002.jpg"
              alt=""
            />
            <div class="prize">
              <span class="ranking fontbold"> 2nd Prize </span>
              Yang Yunping
            </div>
            <div class="address">Ningbo Second Technician College</div>
          </div>
          <div>
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220329/003.jpg"
              alt=""
            />
            <div class="prize">
              <span class="ranking fontbold"> 2nd Prize </span>
              He Jian
            </div>
            <div class="address">Shenzhen Institute of Technology</div>
          </div>
        </div> -->
        <!-- <div class="three">
          <div class="lastchild">
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220329/004.jpg"
              alt=""
            />
            <div class="prize">
              <span class="ranking fontbold"> 3rd Prize </span>
              Liu Han
            </div>
            <div class="address">Shenzhen Polytechnic</div>
          </div>
          <div>
            <img
              src="http://meshmellow.oss-cn-hangzhou.aliyuncs.com/admin/files/20220329/005.jpg"
              alt=""
            />
            <div class="prize">
              <span class="ranking fontbold"> 3rd Prize </span>
              Zhuang Kai
            </div>
            <div class="address">
              Guangdong Literature & Art Vocational College
            </div>
          </div>
        </div> -->
      </div>
      <div class="youth" v-show="!typelab">
        <div v-for="(item, index) in resultlist" :key="index" class="center">
          <div class="toimage">
            <div class="artwork">
              <img :src="item.full_work_files" alt="" />
            </div>
            <div class="avator">
              <img :src="item.full_avatar" alt="" />
            </div>
          </div>
          <div style="margin-top: 28px">
            <span style="color: #bf0075; font-weight: 800; font-size: 24px">
              {{ rank(item.ranked) }}</span
            >
            <span
              style="
                color: #000;
                font-weight: 800;
                font-size: 24px;
                display: inline-block;
                margin-left: 10px;
              "
              >{{ item.username }}</span
            >
          </div>
          <div style="font-size: 18px; line-height: 48px">
            {{ item.school }}
          </div>
        </div>
      </div>
      <div class="more" style="margin-top: 40px" v-if="typelab" @click="more()">
        See more >
      </div>
    </div>
  </div>
</template>

<script>
import { skilldetail, competition_result } from "@/api/api";
import axios from "axios";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      skillslist: [],
      imagelist: [],
      result: {},
      videolist: [],
      typelab: true,
      gamelist: [],
      resultlist: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    const artworkId = Number(this.$route.path.replace("/skillsdetail/", ""));
    skilldetail({ id: artworkId }).then((res) => {
      this.skillslist = res.data;
      let image = JSON.parse(this.skillslist.image);
      let video = JSON.parse(this.skillslist.video);
      if (video) {
        this.videolist = Object.values(video);
      }
      this.imagelist = image;
    });
    competition_result({
      year: "2022",
    }).then((res) => {
      this.resultlist = res.data.msgList;
      console.log(this.resultlist, 12344);
      // console.log(res, 56789);
    });
    this.getgame();
    // console.log(this.getgame());
    console.log(this.gamelist, 111);
  },
  methods: {
    rank(e) {
      if (e == "1") {
        return "1st Prize";
      }
      if (e == "2") {
        return "2nd Prize";
      }
      if (e == "3") {
        return "3rd Prize";
      }
    },
    totype() {
      this.typelab = !this.typelab;
    },
    getgame() {
      var sd;
      axios({
        method: "get",
        url: "/competition/3?lang=en",
        baseURL: "https://api.meshmellow.cn",
      }).then(function (res) {
        sd = res.data.data.award_artworks;
      });
      setTimeout(() => {
        this.gamelist = sd;
        console.log(this.gamelist, 1213);
      }, 2000);
    },
    more() {
      window.open("https://vertexmonkey.net/contest/3", "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "../sass/index";
.staff {
  .poin {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .game {
    display: flex;
    flex-direction: column;
    width: 23%;
    align-items: center;
    // justify-content: center;
    // overflow: hidden;
    margin-bottom: 20px;
    .uname {
      margin-top: 5px;
      font-size: 18px;
      font-weight: 800;
    }
    .ushool {
      margin-top: 5px;
      font-size: 18px;
    }
    .toimage {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 190px;
      overflow: hidden;
      img {
        max-width: none;
        // width: auto !important;

        height: 200px;
      }
    }
  }
}
.youth {
  display: flex;
  flex-direction: column;
  .center {
    display: flex;
    flex-direction: column;
    align-content: center;
    // justify-items: center;
    align-items: center;
    margin-bottom: 32px;
  }
  .toimage {
    display: flex;
    height: 500px;
    overflow: hidden;
    // :nth-child(1) {
    // max-width: 600px;
    // height: auto;
    // }
    .artwork {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 600px;
      overflow: hidden;
      img {
        width: 800px !important;
      }
      // width: 600px;
      // height: fit-content;
    }
    .avator {
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 100%;
      img {
        width: 440px;
      }
    }
  }
}

.forvideo {
  position: relative;
  display: flex;
  // align-items: center;
  // justify-content: center;
  .start {
    position: absolute;
    // width: 40px;
    text-align: center;
    top: 45%;
    right: 53%;
  }
}
.active {
  // width: 54px;
  padding: 10px;
  // height: 40px;
  background: #bf0075;
  color: #fff;
  size: 80px;
  transition: 2s all;
}
.result {
  max-width: 1280px;
  margin: 132px auto 122px;
  .resulttitle {
    @include fontstyle(#bf0075, 50px, 800，auto);
  }
  .resulttype {
    margin: 51px auto 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    :nth-child(1) {
      margin-right: 10px;
    }
  }
  .prize {
    margin: 28px AUTO 16px;
    @include fontstyle(#000, 24px, bold，auto);
  }
  .address {
    margin-bottom: 70px;
    @include fontstyle(#000, 18px, 400，auto);
  }

  .one {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .two {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 47px;
    .lastchild {
      margin-right: 45px;
    }
  }
  .three {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .lastchild {
      margin-right: 45px;
    }
  }
  img {
    display: block;
  }

  .more {
    &:hover {
      @include fontstyle(#bf0075, 16px, bold);
      text-decoration: underline;
    }
  }
}

.detail {
  max-width: 1280px;
  margin: 132px auto 122px;
  .titleskills {
    text-align: left;
  }
}
.viewcenter {
  background-color: #f7f7f7;
  .viewinter {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    padding: 120px 0px;
    :nth-child(n) {
      flex: 1;
    }
    .el-carousel__item {
      // border: 6px solid #bf0075;
    }
    .forimage {
      width: 800px;
      height: 450px;
      display: flex;
      align-items: center;
      img {
        display: block;
      }
    }
    .el-carousel__item img {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 1280px) {
  .detail {
    margin: 66px auto 100px;
    max-width: 760px;
    .titlenew {
      font-size: 40px;
    }
  }
  .staff {
    .game {
      width: 48%;
      .toimage {
        width: 100%;
        overflow: hidden;
        img {
          max-width: none;
          height: 260px;
        }
      }
    }
  }
  .youth {
    padding: 0 20px;
    .toimage {
      height: auto;
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
      }
      // :nth-child(1) {
      //   max-width: 340px;
      //   height: auto !important;
      // }
    }
  }
}
@media screen and (max-width: 768px) {
  .staff {
    .game {
      width: 95%;
      .toimage {
        width: 100%;
        overflow: hidden;
        img {
          max-width: none;
          height: 260px;
        }
      }
    }
  }
  .viewcenter {
    // background-color: #f7f7f7;
    .viewinter {
      padding: 0px !important;
    }
  }

  .detail {
    padding: 30px 20px;
  }

  .forvideo {
    align-items: center !important;
    video {
      height: 207px;
      width: 100%;
      object-fit: fill;
      object-position: center;
      // width: 155px;
    }
    .start {
      //  width: 30px !important;
      display: none;
    }
  }
  .staff {
    padding: 30px 20px;
  }
  .two {
    flex-direction: column;
    .lastchild {
      margin-right: 0px !important;
    }
  }
  .three {
    flex-direction: column;
    .lastchild {
      margin-right: 0px !important;
    }
  }
}
</style>
