<template>
  <div class="news">
    <div class="title">
      <div class="titlenew fontbold">News</div>
    </div>
    <div class="titlelist">
      <div
        v-for="(item, index) in typelist"
        :key="index"
        :class="typeclass[index] && 'active'"
        @click="selectnewstype(index, item.gdsc_group_type_name)"
      >
        {{ item.gdsc_group_type_name }}
      </div>
    </div>
    <div class="center">
      <div v-for="(item, index) in itemlist" :key="index" class="fornews">
        <div class="tonewimage">
          <img
            :src="
              'http://iadge.oss-cn-hangzhou.aliyuncs.com/' + item.image
            "
            alt=""
            class="hand"
            @click="tonewspath(item.id)"
          />
        </div>
        <div class="totitle">
          <div @click="tonewspath(item.id)">{{ item.title }}</div>
          <div>{{ item.creat_time }}</div>
          <div>{{ item.intro }}</div>
          <!-- <router-link :to="'/newsdetail/' + item.id"> -->
          <div @click="tonewspath(item.id)">See more ></div>
          <!-- </router-link> -->
        </div>
      </div>
    </div>
    <div class="pagin">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="1"
        @current-change="tonext"
        :page-count="pages"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Backtop } from "@/utils/util";
import { newstype, newslist } from "@/api/api";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      // 新闻分类
      typelist: [],
      // 新闻列表翻页
      itemlist: [],
      //分类样式
      typeclass: [[true]],
      // 分页次数
      pages: 1,
      // isActive: false,
      // 翻页数据更替参数
      parameter: {
        pageSize: 4,
        page: 1,
        year: 2022,
        groupTypeName: "",
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    newstype({
      year: "2022",
    }).then((res) => {
      this.typelist = res.data.groupTypeName;
      console.log(res.data, 12);
    });
    this.getnewlist();
  },
  methods: {
    tonewspath(el) {
      this.$router.push({ name: "newsdetail", params: { id: el } });
    },
    selectnewstype(index, name) {
      this.typeclass = [];
      this.typeclass[index] = true;
      this.parameter.groupTypeName = name;
      this.parameter.page = 1;
      // console.log(this.typeclass,123);
      this.getnewlist();
    },
    getnewlist() {
      newslist({
        ...this.parameter,
      }).then((res) => {
        // console.log(res.data.msgList.list,123);
        // let tt = res.data.msgList.list.sort((a, b) => {
        //   // console.log(a, b,123);
        //   return b.recommend - a.recommend;
        // });
        // console.log(res.data, 12);
        this.itemlist = res.data.msgList.list;
        this.pages = res.data.msgList.pages;
        // console.log(this.itemlist, res.data.msgList.pages, 123);
      });
    },
    tonext(page) {
      Backtop();
      this.parameter.page = page;
      this.getnewlist();
    },
    // toprev(e,f,g){
    //   console.log(e,f,g);
    // }
  },
};
</script>

<style lang="scss">
.hand {
  cursor: pointer;
}
@import "../sass/index";
.news {
  max-width: 1280px;
  margin: 132px auto 215px;
  .title {
    .titlenew {
      @include fontstyle(#1b78c2, 80px, bold, auto);
    }
  }
  .titlelist {
    @include fontstyle(#000, 16px, 400);
    margin: 66px auto 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    :nth-child(n + 1) {
      margin-left: 20px;
    }
  }
  .center {
    display: flex;
    flex-direction: column;
    .fornews {
      display: flex;
      margin-top: 80px;

      .tonewimage {
        flex: 1;
      }
      .totitle {
        text-align: left;
        display: flex;
        flex-direction: column;
        // align-self: flex-end;
        justify-content: space-between;
        margin-left: 90px;
        flex: 1;
        :nth-child(1) {
          @include fontstyle(#000, 24px, bold, pointer);
          &:hover {
            text-decoration: underline;
          }
        }
        :nth-child(2) {
          @include fontstyle(#1b78c2, 14px, 400, auto);
        }
        :nth-child(3) {
          @include fontstyle(#000, 18px, bold, auto);
        }
        :nth-child(4) {
          &:hover {
            @include fontstyle(#bf0075, 16px, bold);
            text-decoration: underline;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .news {
    max-width: 760px;
    margin: 66px auto 100px;
  }
}
@media screen and (max-width: 768px) {
  .news {
    padding: 30px 20px;
    margin: 0 auto 215px;
    .title {
      .titlenew {
        @include fontstyle(#1b78c2, 32px, bold, auto);
      }
    }
    .center {
      .fornews {
        flex-direction: column;
        .totitle {
          margin-left: 0px;
          :nth-child(1) {
            margin-top: 10px;
          }
          :nth-child(2) {
            margin-top: 10px;
          }
          :nth-child(3) {
            margin-top: 10px;
          }
          :nth-child(4) {
            margin-top: 10px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.active {
  // width: 54px;
  padding: 10px;
  // height: 40px;
  background: #bf0075;
  color: #fff;
  size: 80px;
  transition: 2s all;
}
.pagin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
.el-pager {
  .btn-next {
  }
  button {
    background-color: #fff !important;
    color: #000;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background-color: #fff !important;
    color: #000;
  }
  li {
    background-color: #fff !important;
  }
  .number {
    width: 70px;
    height: 70px;
  }
  .btn-prev {
    width: 70px !important;
    height: 70px !important;
  }
  .is-first {
    width: 70px !important;
    height: 70px !important;
  }
  .active {
    background-color: #bf0075 !important;
  }
}
</style>
