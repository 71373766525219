<template>
  <div class="skills">
    <div class="title">
      <div class="titlenew fontbold">Skills</div>
    </div>
    <!-- <div class="titleskills">
      GDSC 2022 ipsum dolor sit amet, consectetur adipiscing elit, sed do
      eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
      suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
      lacus vel facilisis.
    </div> -->
    <div
      class="skillscenter"
      v-for="(item, index) in skillslist.list"
      :key="index"
    >
      <div class="forimg">
        <!--     JSON.parse(item.image) -->
        <img
          :src="'http://iadge.oss-cn-hangzhou.aliyuncs.com/' + item.cover"
          alt=""
        />
      </div>
      <div class="fortitle">
        <div>
          {{ item.title }}
        </div>
        <div>
          {{ item.intro }}
        </div>
        <div></div>
        <div @click="tonewspath(item.id)">See more ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { skillnews } from "@/api/api";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      skillslist: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    skillnews({
      pageSize: 3,
      page: 1,
      year: 2022,
    }).then((res) => {
      let tt = res.data.msgList.list.sort((a, b) => {
        // console.log(a, b,123);
        return b.recommend - a.recommend;
      });
      // console.log(tt, 8888);
      this.skillslist = res.data.msgList;
      // console.log(123, res.data.msgList);

      // this.skillslist = res.data.msgList.list.map((a, b, c) => {

      //   // console.log(a, b, c);
      // });
      // console.log(this.skillslist, 123);
      // this.skillslist.list.forEach((e, f) => {
      // console.log(JSON.parse(e.image), f);
      // });
      // var fd=this.skillslist.list
      console.log(res.data);
    });
  },
  methods: {
    tonewspath(el) {
      this.$router.push({ name: "skillsdetail", params: { id: el } });
      // console.log(el,123);
    },
  },
};
</script>

<style  lang="scss" scoped>
@import "../sass/index";
.skills {
  max-width: 1280px;
  margin: 132px auto 215px;
  .titleskills {
    line-height: 32px;
    @include fontstyle(#000, 18px, 400, auto);
    text-align: left;
    margin: 54px auto 0px;
  }
  .skillscenter {
    margin-top: 82px;
    display: flex;
    .forimg {
      width: 33%;
      margin-right: 90px;
    }
    .fortitle {
      text-align: left;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      :nth-child(1) {
        padding-left: 16px;
        height: 66px;
        line-height: 66px;
        @include fontstyle(#fff, 30px, bold, auto);
        background: linear-gradient(90deg, #6e2d8b 0%, #1b78c2 100%);
      }
      :nth-child(4) {
        &:hover {
          @include fontstyle(#bf0075, 16px, bold);
          text-decoration: underline;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .skills {
    margin: 66px auto 100px;
    max-width: 760px;
    .htdes {
      img {
        height: auto !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .skillscenter {
    margin-top: 40px !important;
  }
  .skills {
    padding: 30px 20px;
    margin: 0 auto 215px;
    .title {
      .titlenew {
        @include fontstyle(#1b78c2, 32px, bold, auto);
      }
    }
    .skillscenter {
      display: flex;
      flex-direction: column;
      .forimg {
        width: 100%;
        margin-right: 0px;
        img {
          display: block;
          width: 100%;
        }
      }
      .fortitle {
        text-align: center;
        :nth-child(1) {
          @include fontstyle(#fff, 18px, bold, auto);
        }
        :nth-child(4) {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
