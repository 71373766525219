<template>
  <div class="participate">
    <div class="title">
      <div class="titlenew fontbold">Participate</div>
    </div>
    <div class="titlelist">
      <!--  :class="{ active: index == isActive }"
        @click="changeValue(index)" -->
      <div
        v-for="(item, index) in typelist"
        :key="index"
        :class="typeclass[index] && 'active'"
        @click="selectnewstype(index, item.gdsc_participate_group_type_name)"
      >
        {{ item.gdsc_participate_group_type_name }}
      </div>
    </div>
    <div class="center">
      <div v-for="(item, index) in itemlist" :key="index" class="fornews">
        <div class="tonewimage">
          <img
            :src="
              'http://iadge.oss-cn-hangzhou.aliyuncs.com/' + item.image
            "
            alt=""
          />
        </div>
        <div class="totitle">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="pagin">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="1"
        @current-change="tonext"
        :page-count="pages"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Backtop } from "@/utils/util";
import { participate, particlist } from "@/api/api";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      // 新闻分类
      typelist: [],
      // 新闻列表翻页
      itemlist: [],
      //分类样式
      typeclass: [[true]],
      // 分页次数
      pages: 1,
      // isActive: false,
      // 翻页数据更替参数
      parameter: {
        pageSize: 9,
        page: 1,
        year: 2022,
        groupTypeName: "",
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    participate({
      year: "2022",
    }).then((res) => {
      this.typelist = res.data.groupTypeName;
      console.log(res.data, 12);
    });
    this.getnewlist();
  },
  methods: {
    tonewspath(el) {
      this.$router.push({ name: "newsdetail", params: { id: el } });
    },
    selectnewstype(index, name) {
      this.typeclass = [];
      this.typeclass[index] = true;
      this.parameter.groupTypeName = name;
      this.parameter.page = 1;
      // console.log(this.typeclass,123);
      this.getnewlist();
    },
    getnewlist() {
      particlist({
        ...this.parameter,
      }).then((res) => {
        // console.log(res.data.msgList.list,123);
        // let tt = res.data.msgList.list.sort((a, b) => {
        //   // console.log(a, b,123);
        //   return b.recommend - a.recommend;
        // });
        // console.log(res.data, 12);
        this.itemlist = res.data.msgList.list;
        this.pages = res.data.msgList.pages;
        // console.log(this.itemlist, res.data.msgList.pages, 123);
      });
    },
    tonext(page) {
      Backtop();
      this.parameter.page = page;
      this.getnewlist();
    },
    // toprev(e,f,g){
    //   console.log(e,f,g);
    // }
  },
};
</script>

<style lang="scss">
@import "../sass/index";
.participate {
  max-width: 1280px;
  margin: 132px auto 215px;
  .title {
    .titlenew {
      @include fontstyle(#1b78c2, 80px, bold, auto);
    }
  }
  .titlelist {
    @include fontstyle(#000, 16px, 400);
    margin: 66px auto 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    :nth-child(n + 1) {
      margin-left: 20px;
    }
  }
  .center {
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-content: center;
    flex-wrap: wrap;
    // :nth-child(3) {
    //   padding: 80px 0px 0px 0 !important;
    // }
    .fornews {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 33.3%;
      padding: 80px 41px 54px 0;
      box-sizing: border-box;
      &:nth-child(-n + 4) {
        padding: 80px 41px 0px 0;
      }
      // &:nth-child(n + 4) {
      //   padding: 0px 41px 0px 0;
      // }

      .tonewimage {
        display: flex;
        justify-content: center;
        align-items: center;
        // flex: 1;
        overflow: hidden;
      }
      .totitle {
        text-align: left;
        display: flex;
        flex-direction: column;
        // align-self: flex-end;
        justify-content: space-between;
        // margin-left: 90px;
        flex: 1;
        margin: 32px auto 56px;
        :nth-child(1) {
          @include fontstyle(#000, 24px, bold, auto);
        }
        :nth-child(2) {
          @include fontstyle(#1b78c2, 14px, 400, auto);
        }
        :nth-child(3) {
          @include fontstyle(#000, 18px, bold, auto);
        }
        :nth-child(4) {
          &:hover {
            @include fontstyle(#bf0075, 16px, bold);
            text-decoration: underline;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .participate {
    max-width: 760px;
    margin: 66px auto 100px;
  }
}
@media screen and (max-width: 768px) {
  .participate {
    padding: 30px 20px;
    margin: 0 auto 215px;
    .title {
      .titlenew {
        @include fontstyle(#1b78c2, 32px, bold, auto);
      }
    }
    .center {
      justify-content: center;
      .fornews {
        flex-direction: column;
        max-width: 100% !important;
        padding: 80px 0px 0px 0 !important;
        .totitle {
          text-align: center !important;
          //   margin-left: 0px;
          :nth-child(1) {
            margin-top: 10px;
          }
          :nth-child(2) {
            margin-top: 10px;
          }
          :nth-child(3) {
            margin-top: 10px;
          }
          :nth-child(4) {
            margin-top: 10px;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.active {
  // width: 54px;
  padding: 10px;
  // height: 40px;
  background: #bf0075;
  color: #fff;
  size: 80px;
  transition: 2s all;
}
.pagin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
.el-pager {
  .btn-next {
  }
  button {
    background-color: #fff !important;
    color: #000;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background-color: #fff !important;
    color: #000;
  }
  li {
    background-color: #fff !important;
  }
  .number {
    width: 70px;
    height: 70px;
  }
  .btn-prev {
    width: 70px !important;
    height: 70px !important;
  }
  .is-first {
    width: 70px !important;
    height: 70px !important;
  }
  .active {
    background-color: #bf0075 !important;
  }
}
</style>
