<template>
  <div class="registrantion">
    <div class="title">
      <div class="titlenew fontbold">Registration</div>
    </div>
    <div class="from">
      <div class="box1">
        <div class="totle">Competitor Country *</div>
        <div class="put">
          <el-input v-model="competitor_country" placeholder="" />
        </div>
      </div>
      <div class="box1">
        <div class="totle">Competitor Name *</div>
        <div class="put">
          <el-input v-model="competitor_name" placeholder="" />
        </div>
      </div>
      <div class="box1">
        <div class="totle">Competitor Email *</div>
        <div class="put">
          <el-input v-model="competitor_email" placeholder="" />
        </div>
      </div>
      <div class="box1">
        <div class="totle">Expert Name *</div>
        <div class="put"><el-input v-model="expert_name" placeholder="" /></div>
      </div>
      <div class="box1">
        <div class="totle">Expert Email *</div>
        <div class="put">
          <el-input v-model="expert_email" placeholder="" />
        </div>
      </div>
      <div class="box1">
        <div class="totle">Timezone (UTC +/-) *</div>
        <div class="put"><el-input v-model="timezone" placeholder="" /></div>
      </div>
      <div class="box1">
        <div class="totle">
          The Expert is able to join assessment sessions during the week *
        </div>
        <div class="put">
          <el-radio-group v-model="radio1" @change="change()" class="ml-4">
            <el-radio label="1" size="large">Yes</el-radio>
            <el-radio label="0" size="large">Uncertain</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="submit">
        <div @click="submit" :class="(submitstaus && 'clicK') || 'noclick'">
          Submit
        </div>
        <div @click="clean">Clear form</div>
      </div>
    </div>
  </div>
</template>

<script>
import { registrationadd } from "@/api/api";
export default {
  data() {
    return {
      competitor_country: "",
      competitor_name: "",
      competitor_email: "",
      expert_name: "",
      expert_email: "",
      timezone: "",
      is: "",
      radio1: "",
    };
  },
  computed: {
    submitstaus() {
      console.log(1);
      if (
        this.competitor_country &&
        this.competitor_name &&
        this.competitor_email &&
        this.expert_name &&
        this.expert_email &&
        this.timezone &&
        this.radio1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    change(e) {
      console.log(e, this.radio1);
    },
    clean() {
      this.competitor_country = "";
      (this.competitor_name = ""),
        (this.competitor_email = ""),
        (this.expert_name = ""),
        (this.expert_email = ""),
        (this.timezone = ""),
        (this.is = ""),
        (this.radio1 = "");
    },
    async submit() {
      if (!this.submitstaus) return;
      const res = await registrationadd({
        lang: "en",
        competitor_country: this.competitor_country,
        competitor_name: this.competitor_name,
        competitor_email: this.competitor_email,
        expert_name: this.expert_name,
        expert_email: this.expert_email,
        timezone: this.timezone,
        is_able_during_the_week_join: this.radio1,
      });
      res.success && this.success(res.data.mess);
      console.log(res);
    },
    success(e) {
      alert(e);
      this.competitor_country = "";
      (this.competitor_name = ""),
        (this.competitor_email = ""),
        (this.expert_name = ""),
        (this.expert_email = ""),
        (this.timezone = ""),
        (this.is = ""),
        (this.radio1 = "");
    },
  },
};
</script>

<style lang="scss" >
@import "../sass/index";
.registrantion {
  max-width: 1280px;
  margin: 132px auto 215px;
  .from {
    margin-top: 50px;
    background: #faf9fd;
    padding: 60px 0 190px 0;
    .box1 {
      margin: 0 auto;
      max-width: 640px;
      text-align: left;
      margin-bottom: 21px;
      .totle {
        height: 66px;
        font-size: 18px;
        font-weight: bold;
        color: #faf9fd;
        line-height: 66px;
        padding-left: 20px;
        background: linear-gradient(90deg, #6e2d8b 0%, #1b78c2 100%);
      }
      .put {
        background: #ffffff;
        height: 100px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        .el-input__inner {
          max-width: 295px;
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
        }
        .el-radio-group {
          display: grid;
        }
      }
    }
    .submit {
      max-width: 640px;
      display: flex;
      margin: 30px auto 0px;
      align-items: center;
      justify-content: space-between;
      :nth-child(1) {
        // height: 48px;
        // line-height: 48px;
        padding: 16px 25px;
        font-weight: bold;
        cursor: pointer;
      }
      .clicK {
        background: #d8007f;
        color: #ffffff;
      }
      .noclick {
        background: #ccc;
        cursor: no-drop;
      }
      :nth-child(2) {
        &:hover {
          color: #d8007f;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .registrantion {
    margin: 66px auto 100px;
    max-width: 760px;
  }
}
@media screen and (max-width: 768px) {
  .registrantion {
    //  max-width: 400px;
    padding: 30px 20px;
    margin: 0 auto 215px;
    .titlenew {
      font-size: 44px;
    }
    .from {
      padding: 20px 20px !important;
    }
    .put {
      .el-input__inner {
        max-width: 200px !important;
      }
    }
    .box1 {
      .totle {
        height: auto !important;
      }
    }
  }
}
</style>