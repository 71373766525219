<template>
  <div class="photos">
    <div class="title">
      <div class="titlenew fontbold">Photos</div>
    </div>
    <div class="titlelist">
      <div
        v-for="(item, index) in photostype"
        :key="index"
        class="photostype"
        :class="typeclass[index] && 'active'"
        @click="selectnewstype(index, item.gdsc_photos_group_type_name)"
      >
        {{ item.gdsc_photos_group_type_name }}
      </div>
    </div>
    <div class="center">
      <div
        v-for="(item, index) in photoslist"
        :key="index"
        class="photoslist hand"
        @click="tonewspath(item.id)"
      >
        <img
          :src="'http://iadge.oss-cn-hangzhou.aliyuncs.com/' + item.image"
          alt=""
        />
      </div>
    </div>
    <div class="pagin">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="1"
        @current-change="tonext"
        :page-count="pages"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Photoalbum, Photostype } from "@/api/api";
import { Backtop } from "@/utils/util";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      photostype: [],
      photoslist: [],
      typeclass: [[true]],
      parameter: {
        pageSize: 8,
        page: 1,
        year: 2022,
        groupTypeName: "",
        classify: "photos",
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    Photoalbum({
      year: "2022",
    }).then((res) => {
      this.photostype = res.data.groupTypeName;
    });
    this.getphotoslist();
  },
  methods: {
    tonewspath(el) {
      this.$router.push({ name: "phtossdetail", params: { id: el } });
      // console.log(el,123);
    },

    selectnewstype(index, name) {
      this.typeclass = [];
      this.typeclass[index] = true;
      this.parameter.groupTypeName = name;
      this.parameter.page = 1;
      this.getphotoslist();
    },
    tonext(page) {
      Backtop();
      this.parameter.page = page;
      this.getphotoslist();
    },
    getphotoslist() {
      Photostype({
        ...this.parameter,
      }).then((res) => {
        this.photoslist = res.data.msgList.list;
        this.pages = res.data.msgList.pages;
      });
    },
  },
};
</script>

<style  lang="scss">
@import "../sass/index";
.photos {
  max-width: 1280px;
  margin: 132px auto 215px;
  .center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .photoslist {
      width: 575px;
      height: 317px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      &:nth-child(odd) {
        margin: 0 50px 50px 0;
      }
      &:nth-child(even) {
        margin: 0 0px 50px 0;
      }

      img {
        // max-width: 45%;
        display: block;
      }
    }
  }
}
.active {
  // width: 54px;
  padding: 10px;
  // height: 40px;
  background: #bf0075;
  color: #fff;
  size: 80px;
  transition: 2s all;
}
.titlelist {
  @include fontstyle(#000, 16px, 400);
  margin: 66px auto 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  :nth-child(n + 1) {
    margin-right: 14px;
    margin-left: 20px;
  }
}
.pagin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
.el-pager {
  .btn-next {
  }
  button {
    background-color: #fff !important;
    color: #000;
  }
  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background-color: #fff !important;
    color: #000;
  }
  li {
    background-color: #fff !important;
  }
  .number {
    width: 70px;
    height: 70px;
  }
  .btn-prev {
    width: 70px !important;
    height: 70px !important;
  }
  .is-first {
    width: 70px !important;
    height: 70px !important;
  }
  .active {
    background-color: #bf0075 !important;
  }
}
@media screen and (max-width: 1280px) {
  .photos {
    max-width: 760px;
    margin: 66px auto 100px;
    .center {
      .photoslist {
        &:nth-child(n) {
          margin: 0 50px 50px 0;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .photos {
    padding: 30px 20px;
    margin: 0 auto 215px;
    .title {
      .titlenew {
        @include fontstyle(#1b78c2, 32px, bold, auto);
      }
    }
    .center {
      .photoslist {
        &:nth-child(n + 1) {
          margin: 0 0px 50px 0;
        }
      }
    }
  }
}
</style>
